import { Product } from '@models'

class ProductService {
  factory(): Product {
    return {
      archivedAt: null,
      createdAt: new Date(),
      createdByUid: '',
      deletedAt: null,
      description: '',
      name: '',
      options: [],
      uid: '',
      updatedAt: new Date(),
    }
  }
}

export const productService = new ProductService()
