import React from 'react'
import { useFirebaseAuth } from '@hooks'
import { AppRoutes } from '@components'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { pageActions } from './redux/page'

const App = () => {
  useFirebaseAuth()
  const dispatch = useDispatch()
  const history = useHistory()

  const routeListener = history.listen(() => {
    dispatch(pageActions.setRouteChanged(true))
    // Unlisten
    routeListener()
  })

  return (
    <div className="app">
      <AppRoutes></AppRoutes>
    </div>
  )
}

export default App
