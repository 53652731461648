import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { pageSelectors, RootState, pageActions } from '@redux'
import { PageHelmet, Content } from '@components'
import { Redirect } from 'react-router-dom'

export const AboutPage = () => {
  const page = useSelector((state: RootState) => pageSelectors.getPageBySlug(state, 'about'))
  const dispatch = useDispatch()
  dispatch(pageActions.setFromPhotoPage(false))

  if (!page) {
    return <Redirect to="/error"></Redirect>
  }

  return (
    <div className="page">
      <PageHelmet page={page}></PageHelmet>
      <h1>About</h1>
      <Content sections={page.contentSections}></Content>
    </div>
  )
}
