import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

interface ProtectedRouteProps extends RouteProps {
  redirect: string
  isAuthenticated: boolean
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {

  if(!props.isAuthenticated) {
    return <Redirect to={props.redirect} />
  }

  return (
    <Route {...props} component={props.component} />
  )
}