import { RootState } from '..'

class RevisionSelectors {
  getRevisions = ({ revision }: RootState) => revision.entities

  getLoading = ({ revision }: RootState) => revision.loading

  getBlogRevisions = ({ revision }: RootState) => revision.entities.filter((r) => r.type === 'page')

  getPageRevisions = ({ revision }: RootState) => revision.entities.filter((r) => r.type === 'page')

  getRevisionByUid = ({ revision }: RootState, uid: string) => revision.entities.find((r) => r.uid === uid)
}
export const revisionSelectors = new RevisionSelectors()
