import moment from 'moment'

class UtilityService {
  arrayMove(array: Array<any>, fromIndex: number, toIndex: number) {
    const newArray = array.slice()
    const startIndex = toIndex < 0 ? newArray.length + toIndex : toIndex
    const item = newArray.splice(fromIndex, 1)[0]
    newArray.splice(startIndex, 0, item)
    return newArray
  }

  parseTimestampToDate(timestamp: any): Date {
    return moment(timestamp.seconds * 1000).toDate()
  }

  parseTimestamp(timestamp: any, format: string = 'Do MMM YYYY'): string {
    if (!timestamp) return ''
    if (timestamp instanceof Date || typeof timestamp === 'string') {
      return moment(timestamp).format(format)
    }
    return moment(timestamp.seconds * 1000).format(format)
  }
}

export const utilityService = new UtilityService()
