import { Order, OrderStatus, OrderPaymentStatus } from '@models'

class OrderService {
  statusFactory(): OrderStatus {
    return {
      note: null,
      status: 'created',
      statusDate: new Date(),
    }
  }

  paymentStatusFactory(): OrderPaymentStatus {
    return {
      note: null,
      paymentStatus: 'pending',
      paymentStatusDate: new Date(),
    }
  }

  factory(): Order {
    return {
      archivedAt: null,
      billingAddress: null,
      createdAt: new Date(),
      createdByUid: '',
      deletedAt: new Date(),
      items: [],
      paymentStatusHistory: [],
      shippingAddress: null,
      statusHistory: [],
      subtotal: 0,
      tax: 0,
      total: 0,
      trackingUrl: null,
      uid: '',
      updatedAt: new Date(),
      userUid: '',
    }
  }
}

export const orderService = new OrderService()
