import { User } from '@models'

export const SET_USERS = '[USER] SET_USERS'
export const SET_AUTH_USER_UID = '[USER] SET_AUTH_USER_UID'

export interface SetUsersAction {
  type: typeof SET_USERS;
  payload: User[];
}

export interface SetAuthUserUidAction {
  type: typeof SET_AUTH_USER_UID;
  payload: string | null;
}

export type UserActionTypes = SetUsersAction | SetAuthUserUidAction
