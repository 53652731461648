import {
  SET_LOADING,
  SET_CAN_GO_BACK,
  SET_MOBILE_NAV_EXPANDED,
  SetLoadingAction,
  SetCanGoBackAction,
  SetMobileNavExpanded,
  SetRouteChanged,
  SET_ROUTE_CHANGED,
  SetPagesAction,
  SET_PAGES,
  SetFromPhotoPage,
  SET_FROM_PHOTO_PAGE,
} from './page.types'
import { Page, Revision } from '@models'
import { AppThunk } from '@redux'
import { pageResource } from '@resources'

class PageActions {
  setPages(pages: Page[]): SetPagesAction {
    return {
      type: SET_PAGES,
      payload: pages,
    }
  }

  setLoading(loading: boolean): SetLoadingAction {
    return {
      type: SET_LOADING,
      payload: loading,
    }
  }

  setCanGoBack(canGoBack: boolean): SetCanGoBackAction {
    return {
      type: SET_CAN_GO_BACK,
      payload: canGoBack,
    }
  }

  setMobileNavExpanded(expanded: boolean): SetMobileNavExpanded {
    return {
      type: SET_MOBILE_NAV_EXPANDED,
      payload: expanded,
    }
  }

  setRouteChanged(changed: boolean): SetRouteChanged {
    return {
      type: SET_ROUTE_CHANGED,
      payload: changed,
    }
  }

  setFromPhotoPage(value: boolean): SetFromPhotoPage {
    return {
      type: SET_FROM_PHOTO_PAGE,
      payload: value,
    }
  }

  publishPage = (page: Page): AppThunk => async (dispatch) => {
    page.published = true
    page.publishedAt = new Date()
    dispatch(this.savePage({ ...page }))
  }

  unpublishPage = (page: Page): AppThunk => async (dispatch) => {
    page.published = false
    dispatch(this.savePage({ ...page }))
  }

  createPage = (page: Page): AppThunk => async (dispatch, getState) => {
    dispatch(this.setLoading(true))
    const state = getState()
    page.createdByUid = state.user.authUserUid
    const result = await pageResource.create(page)
    dispatch(this.setLoading(false))
    return result
  }

  savePage = (page: Page): AppThunk => async (dispatch) => {
    dispatch(this.setLoading(true))
    const result = await pageResource.save(page)
    dispatch(this.setLoading(false))
    return result
  }

  updatePage = (page: Page): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const pages = state.page.entities.map((p) => {
      if (p.uid === page.uid) {
        return page
      }
      return p
    })
    dispatch(this.setPages(pages))
  }

  applyRevision = (page: Page, revision: Revision): AppThunk => (dispatch) => {
    dispatch(this.savePage({ ...page, contentSections: revision.contentSections, revisionUid: revision.uid || '' }))
  }
}

export const pageActions = new PageActions()
