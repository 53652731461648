import { OrderItem } from '@models'

class OrderItemService {
  factory(): OrderItem {
    return {
      option: null,
      product: null,
      quantity: 0,
      subtotal: 0,
      total: 0,
    }
  }
}

export const orderItemService = new OrderItemService()
