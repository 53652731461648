export const PHOTO_BASE_PATH = 'https://firebasestorage.googleapis.com/v0/b/jon-good-photo.appspot.com/o/photos'

export const PHOTO_SIZES = [1920, 1200, 600, 150]

export const APERTURE_VALUES: string[] = [
  '1.2',
  '1.4',
  '1.8',
  '2',
  '2.8',
  '4',
  '5.6',
  '8',
  '10',
  '11',
  '14',
  '16',
  '22',
]

export const SHUTTER_SPEED_VALUES: string[] = [
  '1/8000',
  '1/6400',
  '1/5000',
  '1/4000',
  '1/3200',
  '1/2500',
  '1/2000',
  '1/1600',
  '1/1250',
  '1/1000',
  '1/800',
  '1/640',
  '1/500',
  '1/400',
  '1/320',
  '1/250',
  '1/200',
  '1/160',
  '1/125',
  '1/100',
  '1/80',
  '1/60',
  '1/50',
  '1/40',
  '1/30',
  '1/25',
  '1/20',
  '1/15',
  '1/13',
  '1/10',
  '1/8',
  '1/6',
  '1/5',
  '1/4',
  '0"3',
  '0"4',
  '0"5',
  '0"6',
  '0"8',
  '1s',
  '1.3s',
  '1.6s',
  '2s',
  '2.5s',
  '3.2s',
  '4s',
  '5s',
  '6s',
  '8s',
  '10s',
  '13s',
  '15s',
  '20s',
  '25s',
  '30s',
]

export const ISO_VALUES: string[] = [
  '50',
  '100',
  '125',
  '160',
  '200',
  '250',
  '320',
  '400',
  '500',
  '640',
  '800',
  '1000',
  '1250',
  '1600',
  '2000',
  '2500',
  '3200',
  '4000',
  '5000',
  '6400',
  '8000',
  '10000',
  '12800',
]
