import { PaymentForm } from '@components'
import { checkoutActions } from '@redux'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const CheckoutPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkoutActions.getClientSecret(1000))
  }, [])

  return <PaymentForm></PaymentForm>
}
