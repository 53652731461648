import React, { FormEvent } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

export const PaymentForm = () => {
  if (typeof window === 'undefined') {
    return null
  }
  const stripe = useStripe()
  const elements = useElements()

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#000',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#bababa',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      return
    }

    const result = await stripe.confirmCardPayment('{CLIENT_SECRET}', {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: 'Jon Good',
        },
      },
    })

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message)
    } else {
      // The payment has been processed!
      if (result.paymentIntent?.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        console.log(result)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Card details
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </label>
      <button disabled={!stripe}>Confirm order</button>
      <a href="https://www.stripe.com/" target="_blank" rel="noopener noreferrer">
        <object
          data="/assets/logos/powered-by-stripe.svg"
          type="image/svg+xml"
          className="powered-by-stripe-logo"
          aria-label="Powered by Stripe"
        ></object>
      </a>
    </form>
  )
}
