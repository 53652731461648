import React from 'react'
import { Nav } from '../nav/nav.component'
import { useDispatch, useSelector } from 'react-redux'
import { pageSelectors, pageActions } from '@redux'
import { Link } from 'react-router-dom'

export const Footer = () => {
  const dispatch = useDispatch()
  const expanded = useSelector(pageSelectors.getMobileNavExpanded)

  return (
    <footer>
      <ul className="footer-items">
        <li className="footer-items-nav">
          <Link to="/" className="home-icon">
            <i className="material-icons">home</i>
          </Link>
          <a className="mobile-menu-icon" onClick={() => dispatch(pageActions.setMobileNavExpanded(!expanded))}>
            <i className="material-icons">menu</i>
          </a>
          <Nav position="footer"></Nav>
        </li>
        <li className="footer-items-copyright">
          Images and design copyright &copy; 2020 Jonathan Good. All rights reserved.
        </li>
        <li className="footer-items-social">
          <a href="https://www.instagram.com/jongood01/" target="_blank" rel="noopener noreferrer">
            <img src="/assets/logos/instagram-logo.png" alt="Instagram link" />
          </a>
          <a href="https://www.facebook.com/JonGoodPhoto/" target="_blank" rel="noopener noreferrer">
            <img src="/assets/logos/facebook-logo.png" alt="Facebook link" />
          </a>
        </li>
      </ul>
    </footer>
  )
}
