import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { blogSelectors, pageActions } from '@redux'
import { PageHelmet, Content } from '@components'
import { Redirect, useParams } from 'react-router-dom'
import { Blog } from '@models'

interface RouteParams {
  slug: string;
}

export const BlogPage = () => {
  const dispatch = useDispatch()
  const params = useParams<RouteParams>()
  const slug = params.slug
  
  const blogs = useSelector(blogSelectors.getBlogs)
  const [loaded, setLoaded] = useState(false)
  const [blog, setBlog] = useState<Blog | undefined>()

  useEffect(() => {
    dispatch(pageActions.setFromPhotoPage(false))
    setBlog(blogs.find(b => b.slug === slug))
    console.log(blog);
    
    setLoaded(true)
  }, [slug, blogs])

  if (loaded && (!blog || !blog.published)) {
    return <Redirect to="/not-found"></Redirect>
  }

  if(!blog) {
    return null
  }

  return (
    <div className="page">
      <PageHelmet page={blog}></PageHelmet>
      <Content sections={blog.contentSections}></Content>
    </div>
  )
}
