import { useDispatch } from "react-redux"
import { auth } from '../resources'
import { userActions } from "../redux/user"

export const useFirebaseAuth = () => {
  const dispatch = useDispatch()

  auth.onAuthStateChanged((val) => {
    if(val) {
      dispatch(userActions.setAuthUserUid(val.uid))
      dispatch(userActions.loadUsers())
    }  
  })
}