import { ProductOption, ProductOptionDimensions } from '@models'

class ProductOptionService {
  dimensionsFactory(): ProductOptionDimensions {
    return {
      depth: 0,
      height: 0,
      unit: 'cm',
      width: 0,
    }
  }

  factory(): ProductOption {
    return {
      availableQty: 0,
      description: '',
      dimensions: this.dimensionsFactory(),
      holdQty: 0,
      limitedEditionTotal: 0,
      name: '',
      price: 0,
      published: false,
      salePrice: null,
      soldQty: 0,
      sortOrder: 0,
      totalQty: 0,
    }
  }
}

export const productOptionService = new ProductOptionService()
