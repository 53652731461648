import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/analytics'
import { FIREBASE_CONFIG } from '../constants/firebase'

firebase.initializeApp(FIREBASE_CONFIG)
if (typeof window !== 'undefined') {
  firebase.analytics()
}

export const firestore = firebase.firestore()

export const storage = firebase.storage()

export const auth = firebase.auth()

export const functions = firebase.functions()
