import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pageActions, RootState, pageSelectors, photoSelectors, categorySelectors } from '@redux'
import { PageHelmet, PhotoGrid } from '@components'
import { Redirect, useParams } from 'react-router-dom'
import { photoService } from '@services'
import { Photo } from '@models'

interface RouteParams {
  category: string
}

export const GalleryPage = () => {
  const dispatch = useDispatch()
  const page = useSelector((state: RootState) => pageSelectors.getPageBySlug(state, 'gallery'))
  const params = useParams<RouteParams>()
  const categorySlug = params.category
  const category = useSelector((state: RootState) => categorySelectors.getCategoryBySlug(state, categorySlug))
  const [photos, setPhotos] = useState<Photo[]>([])
  const rawPhotos = useSelector((state: RootState) => photoSelectors.getPhotosByCategory(state, categorySlug))
  const fromPhotoPage = useSelector(pageSelectors.getFromPhotoPage)
  
  if(!rawPhotos) {
    return null
  }

  if (!page) {
    return <Redirect to="/error"></Redirect>
  }

  useEffect(() => {
    // Filter the photos only to those that are published and apply the category sorting
    // Clear the photos then set a timeout to refresh the grid if we are not coming from a photo modal page
    setPhotos([])
    if(fromPhotoPage) {
      setPhotos(photoService.sortCategory(rawPhotos.filter(p => p.published), categorySlug))
    } else {
      setTimeout(() => {
        setPhotos(photoService.sortCategory(rawPhotos.filter(p => p.published), categorySlug))
      }, 200)
    }

    dispatch(pageActions.setCanGoBack(true))
  }, [categorySlug])

  return (
    <div className={`page${fromPhotoPage ? ' no-fade' : ''}`}>
      <PageHelmet page={page}></PageHelmet>
      <div className="row"><h1>Gallery / {category?.name} </h1></div>
      <div className="row">
        <div className="grid-wrap" id="grid-container">
          { photos.length ? <PhotoGrid photos={photos} maxWidth={1920} margin={2}></PhotoGrid> : null }
        </div>        
      </div>
    </div>
  )
}
