import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { configureStore } from '@redux'
import { Provider } from 'react-redux'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import App from './app.component'

// Allow us to get/set properties on the window in typescript
declare global {
  interface Window { 
    _TRANSFER_STATE_?: object; 
    _ENV_?: string;
    _STRIPE_PUBLIC_KEY_?: string;
    grecaptcha: any;
  }
}

const transferState = window._TRANSFER_STATE_
const environment = window._ENV_
const stripePublicKey = window._STRIPE_PUBLIC_KEY_ || ''

delete window._TRANSFER_STATE_
delete window._ENV_
delete window._STRIPE_PUBLIC_KEY_

const store = configureStore(transferState, environment)
const stripePromise = loadStripe(stripePublicKey);

  ReactDOM.hydrate(
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <App />
          </Suspense>
        </Router>        
      </Elements>
    </Provider>,
    document.getElementById('root')
  )
