import {firestore} from './firebase.resource'
import { Tag } from '@models'

class TagResource {
  public index: string = 'tags'
  private collection = firestore.collection(this.index)

  map(result: any) {
    return result.docs.map(doc => {
      const data = {...doc.data()}
      delete data['createdByUid']
      return data
    }) as Tag[]
  }
  
  sync(callback: (categories: Tag[] ) => void) {
    this.collection.onSnapshot(result => callback(this.map(result)))
  }

  load(): Promise<Tag[]> {
    return new Promise((resolve, reject) => {
      this.collection.onSnapshot(
        (result: any) => {          
          resolve(this.map(result))
        },
        (error: any) => {
          reject(error)
        }
      )
    })
  }

}

export const tagResource = new TagResource()
