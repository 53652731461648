import {firestore} from './firebase.resource'
import { Photo } from '@models'

class PhotoResource {
  public index: string = 'photos'
  private collection = firestore.collection(this.index)

  map(result: any) {
    return result.docs.map(doc => {
      const data = {...doc.data()}
      delete data['createdByUid']
      return data
    }) as Photo[]
  }
  
  sync(callback: (photos: Photo[] ) => void) {
    this.collection.where('deletedAt', '==', null).onSnapshot(result => callback(this.map(result)))
  }

  syncTrash(callback: (photos: Photo[] ) => void) {
    this.collection.where('deletedAt', '>', new Date('2019-01-01')).onSnapshot(result => callback(this.map(result)))
  }

  save(photo: Photo): Promise<void> {
    photo.updatedAt = new Date()
    return this.collection.doc(`/${photo.uid}`).update(photo)
  }

  load(): Promise<Photo[]> {
    return new Promise((resolve, reject) => {
      this.collection.where('deletedAt', '==', null).onSnapshot(
        (result) => {          
          resolve(this.map(result))
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

}

export const photoResource = new PhotoResource()
