import { Order } from '@models'
import { CheckoutActionTypes } from '@redux'
import { SET_CLIENT_SECRET, SET_CHECKOUT_LOADING} from './checkout.types'

export interface CheckoutState {
  clientSecret: string
  loading: boolean
  order: Order | null
}

const initialState: CheckoutState = {
  clientSecret: '',
  loading: false,
  order: null,
}

export function checkoutReducer(state: CheckoutState = initialState, action: CheckoutActionTypes): CheckoutState {
  switch (action.type) {
    case SET_CLIENT_SECRET:
      return { ...state, clientSecret: action.payload }
    case SET_CHECKOUT_LOADING:
      return { ...state, loading: action.payload }  
    default:
      return state
  }
}
