import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { pageSelectors, pageActions } from '@redux'
import { PageHelmet, Content } from '@components'
import { Redirect, useParams } from 'react-router-dom'
import { Page } from '@models'

interface RouteParams {
  slug: string;
}

export const CustomPage = () => {
  const params = useParams<RouteParams>()
  const dispatch = useDispatch()
  const slug = params.slug
  
  const pages = useSelector(pageSelectors.getPages)
  const [loaded, setLoaded] = useState(false)
  const [page, setPage] = useState<Page | undefined>()

  useEffect(() => {
    dispatch(pageActions.setFromPhotoPage(false))
    setPage(pages.find(p => p.slug === slug))
    setLoaded(true)
  }, [slug, pages])

  if (loaded && (!page || !page.published)) {
    return <Redirect to="/not-found"></Redirect>
  }

  if(!page) {
    return null
  }

  return (
    <div className="page">
      <PageHelmet page={page}></PageHelmet>
      <Content sections={page.contentSections}></Content>
    </div>
  )
}
