import { ContentSectionType, ContentBlockType } from '@models'

export const CONTENT_SECTION_TYPES: ContentSectionType[] = [
  { label: 'Full Width', type: 'full-width', icon: 'editor-full-width.png' },
  { label: '2 Blocks Even', type: '2-blocks-even', icon: 'editor-half-even.png' },
  { label: '2 Blocks Left Main', type: '2-blocks-left', icon: 'editor-half-left.png' },
  { label: '2 Blocks Right Main', type: '2-blocks-right', icon: 'editor-half-right.png' },
  { label: '3 Blocks Even', type: '3-blocks-even', icon: 'editor-three-even.png' },
  { label: '3 Blocks Left Main', type: '3-blocks-left', icon: 'editor-three-left.png' },
  { label: '3 Blocks Center Main', type: '3-blocks-center', icon: 'editor-three-center.png' },
  { label: '3 Blocks Right Main', type: '3-blocks-right', icon: 'editor-three-right.png' },
  { label: 'Four Blocks Even', type: '4-blocks-even', icon: 'editor-four.png' },
]

export const CONTENT_BLOCK_TYPES: ContentBlockType[] = [
  { label: 'Container', type: 'container', icon: 'web_asset' },
  { label: 'Blockquote', type: 'blockquote', icon: 'format_quote' },
  { label: 'Heading', type: 'heading', icon: 'title' },
  { label: 'Paragraph', type: 'paragraph', icon: 'notes' },
  { label: 'Photo', type: 'photo', icon: 'insert_photo' },
  { label: 'Post Content', type: 'post-content', icon: 'list_alt' },
  { label: 'Embedded Photo', type: 'embedded-photo', icon: 'crop_original' },
  { label: 'Heading Over Photo', type: 'heading-over-photo', icon: 'featured_play_list' },
  { label: 'Paragraph Over Photo', type: 'paragraph-over-photo', icon: 'library_books' },
  { label: 'Call To Action', type: 'call-to-action', icon: 'link' },
  { label: 'Call To Action External', type: 'call-to-action-external', icon: 'open_in_new' },
]
