import { Message } from '@models'
import { MessageActionTypes, SET_SENT, SET_MESSAGE } from './message.types'
import { messageService } from '@services'

export interface MessageState {
  sent: boolean;
  message: Message;
}

const initialState: MessageState = {
  sent: false,
  message: messageService.factory(),
}

export function messageReducer(state: MessageState = initialState, action: MessageActionTypes): MessageState {
  switch (action.type) {
    case SET_SENT:
      return {
        ...state,
        sent: action.payload,
      }
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      }
    default:
      return state
  }
}
