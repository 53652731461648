import { PhotoFile, Photo } from '@models'
import {
  SET_FILES,
  REMOVE_FILE_BY_INDEX,
  RESET,
  SetFilesAction,
  RemoveFileByIndexAction,
  ResetAction,
} from './file.types'
import { AppThunk } from '..'
import { fileSelectors } from './file.selectors'
import { storage, firestore } from '../../resources'
import { pageActions } from '../page'
import { PHOTO_SIZES, PHOTO_BASE_PATH } from '@constants'

class FileActions {
  setFiles(photos: PhotoFile[]): SetFilesAction {
    return {
      type: SET_FILES,
      payload: photos,
    }
  }
  removeFileByIndex(index: number): RemoveFileByIndexAction {
    return {
      type: REMOVE_FILE_BY_INDEX,
      payload: index,
    }
  }

  reset(): ResetAction {
    return {
      type: RESET,
    }
  }

  appendFiles = (photos: PhotoFile[]): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const files = state.file.entities.concat(photos)
    dispatch(this.setFiles(files))
  }

  updateFilePhoto = (index: number, photo: Photo): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const files = fileSelectors.getFilesCopy(state)
    files[index].photo = photo
    dispatch(this.setFiles(files))
  }

  updateFilePhotoProgress = (index: number, progress: number): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const files = fileSelectors.getFilesCopy(state)
    files[index].progress = progress
    dispatch(this.setFiles(files))
  }

  replaceFile = (photo: Photo): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const storageRef = storage.ref()
    const photoFiles = fileSelectors.getFiles(state)
    const photoFile = photoFiles[0]
    dispatch(pageActions.setLoading(true))

    try {
      for (const size of PHOTO_SIZES) {
        const path = photo.fileName.replace('.jpg', `_${size}x${size}.jpg`)
        await storageRef.child(`photos/${photo.uid}/${path}`).delete()
      }
    } catch (error) {
      console.log(error)
    }

    try {
      const storagePath = `photos/${photo.uid}/${photoFile.file.name}`
      const task = storageRef.child(storagePath).put(photoFile.file)
      task.on('state_changed', (snapshot) => {
        dispatch(this.updateFilePhotoProgress(0, snapshot.bytesTransferred)) // progress of upload
      })
      await task
      const url = await storageRef.child(storagePath).getDownloadURL()
      // Create a url for each photo size - the CF extension will automatically create the images
      const sources = PHOTO_SIZES.map((size) =>
        url.replace('.jpg', `_${size}x${size}.jpg`).replace(PHOTO_BASE_PATH, '')
      )
      await firestore
        .collection('photos')
        .doc(photo.uid)
        .update({
          fileName: photoFile.photo.fileName,
          height: photoFile.photo.height,
          width: photoFile.photo.width,
          sourceLarge: sources[0],
          sourceMedium: sources[1],
          sourceSmall: sources[2],
          sourceTiny: sources[3],
        })
    } catch (error) {
      console.log(error)
    }
    dispatch(pageActions.setLoading(false))
    dispatch(this.reset())
  }

  uploadFiles = (): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const photoFiles = fileSelectors.getFiles(state)
    const storageRef = storage.ref()
    const promises: Promise<boolean>[] = []
    const userUid = state.user.authUserUid

    dispatch(pageActions.setLoading(true))
    for (let index = 0; index < photoFiles.length; index++) {
      const photoFile = photoFiles[index]
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            photoFile.photo.createdByUid = userUid
            const photoRef = await firestore.collection('photos').add(photoFile.photo)
            const storagePath = `photos/${photoRef.id}/${photoFile.file.name}`
            const task = storageRef.child(storagePath).put(photoFile.file)
            task.on('state_changed', (snapshot) => {
              dispatch(this.updateFilePhotoProgress(index, snapshot.bytesTransferred)) // progress of upload
            })
            await task
            const url = await storageRef.child(storagePath).getDownloadURL()
            // Create a url for each photo size - the CF extension will automatically create the images
            const sources = PHOTO_SIZES.map((size) =>
              url.replace('.jpg', `_${size}x${size}.jpg`).replace(PHOTO_BASE_PATH, '')
            )
            await firestore
              .collection('photos')
              .doc(photoRef.id)
              .update({
                uid: photoRef.id,
                sourceLarge: sources[0],
                sourceMedium: sources[1],
                sourceSmall: sources[2],
                sourceTiny: sources[3],
              })
            resolve(true)
          } catch (error) {
            reject(error)
          }
        })
      )
    }
    await Promise.all(promises)
    dispatch(pageActions.setLoading(false))
    dispatch(this.reset())
  }
}

export const fileActions = new FileActions()
