import { useDispatch } from 'react-redux'
import { tagResource } from '../resources'
import { Tag } from '@models'
import { tagActions } from '@redux'

export const useFirebaseTags = () => {
  const dispatch = useDispatch()

  tagResource.sync((tags: Tag[]) => {
    dispatch(tagActions.setTags(tags))
  })
}
