import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEscapeToClose } from '@hooks'
import { PHOTO_BASE_PATH, APP_DOMAIN } from '@constants'
import { RootState, pageSelectors, pageActions, photoSelectors } from '@redux'
import { PageHelmet } from '@components'
import { pageService } from '@services'

interface RouteParams {
  id: string
}

export const PhotoPage = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<RouteParams>()
  const photo = useSelector((state: RootState) => photoSelectors.getPhotoByUid(state, params.id))
  const canGoBack = useSelector(pageSelectors.getCanGoBack)
  const isServer = typeof window === 'undefined' ? true : false
  const [detailExpanded, setDetailExpanded] = useState(false)
  const [aspect, setAspect] = useState('landscape')
  const [photoSource, setPhotoSource] = useState('')
  const [showLeftScollHelp, setShowLeftScrollHelp] = useState(false)
  const [showRightScollHelp, setShowRightScrollHelp] = useState(true)

  if(!photo) {
    return (<Redirect to="/404"></Redirect>)
  }

  const page = pageService.factory()
  page.metaDescription = `${photo.name} - ${photo.description}`;
  page.metaTitle = `Jon Good Photography / ${photo.name}`
  page.ogTitle = page.metaTitle
  page.ogType = 'website'
  page.ogUrl = `${APP_DOMAIN}/photos/${photo.uid}` 
  page.ogImage = PHOTO_BASE_PATH + photo.sourceSmall


  const onClose = () => {
    if(!canGoBack) {
      history.push('/')
      return 
    }
    history.goBack()
  }

  useEffect(() => {
    dispatch(pageActions.setFromPhotoPage(true))
    if(photo && (photo.width === photo.height || (photo.width > photo.height && photo.width / photo.height <= 1.26))) {
      setAspect('square')
    } else if(photo && photo.width > photo.height) {
      setAspect('landscape')
    } else {
      setAspect('portrait')
    }

    const listener = () => {
      setup()
    }
    if (!isServer) {
      setup()
      window.addEventListener('resize', listener)
    }
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [photo])

  useEscapeToClose(onClose)

  const setup = () => {
    const elementWidth = document.getElementById('photo-element')?.offsetWidth || 0
    if(elementWidth >= 1200) {
      setPhotoSource(PHOTO_BASE_PATH + photo.sourceLarge)
    } else {
      setPhotoSource(PHOTO_BASE_PATH + photo.sourceMedium)
    }
  }

  const toggleDetailExpanded = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setDetailExpanded(!detailExpanded)
  }

  const renderScrollHelp = () => {
    if(aspect == 'portrait') {
      return null
    }
    return (
      <div className="photo-scroll-help"><i className={`material-icons left${showLeftScollHelp ? ' show' : ''}`}>
      keyboard_arrow_left
      </i> Swipe to view <i className={`material-icons right${showRightScollHelp ? ' show' : ''}`}>
      keyboard_arrow_right
      </i></div>
    )
  }

  const renderToggleArrow = () => {
    return (
      <div className="photo-detail-toggle" onClick={e => toggleDetailExpanded(e)}>
        <i className={`material-icons${detailExpanded ? ' expanded' : ''}`}>keyboard_arrow_up</i>
      </div>
      )
  }

  const handleImageScroll = (event: React.UIEvent<HTMLDivElement>) => {

    const image = document.getElementById('source-photo')
    if(!image) return
    
    if(event.currentTarget.scrollLeft === 0) {
      setShowLeftScrollHelp(false)
    } else {
      setShowLeftScrollHelp(true)
    }

    if(event.currentTarget.scrollLeft + event.currentTarget.offsetWidth >= image.offsetWidth) {
      setShowRightScrollHelp(false)
    } else {
      setShowRightScrollHelp(true)
    }
    
  }

  if(!photo) {
    return null
  }

  return (
    <>
    <PageHelmet page={page}></PageHelmet>
    <div className="photo" id="photo-element">
      <div className="photo-overlay" onClick={() => onClose()}>
        <div className={`photo-container ${aspect}`} onClick={e => e.stopPropagation()}>
          <div onScroll={e => handleImageScroll(e)} className={`photo-image-wrap ${aspect}`} onClick={e => toggleDetailExpanded(e)}>
            <img id="source-photo" src={photoSource || ''} alt=""/>
          </div>
          {renderScrollHelp()}
          <div className={`photo-details${detailExpanded ? ' expanded' : ''} ${aspect}`}>
            {renderToggleArrow()}
            <h1 onClick={e => toggleDetailExpanded(e)}>{photo.name}</h1>
            <p>{photo.description}</p>
            <div className="tech-details">
              Technical Detail
              <p>{photo.technicalDetail.focalLength}mm @ {photo.technicalDetail.shutterSpeed},  f{photo.technicalDetail.aperture}, ISO {photo.technicalDetail.iso} </p>
            </div>
          </div>  
          <i className="material-icons modal-close-button" onClick={() => onClose()}>
            clear
          </i>          
        </div>
      </div>
    </div>
   </> 
  )
}