import { PhotoActionTypes, SET_PHOTOS, SET_SELECTED_PHOTO_UID, SET_TRASH} from "./photo.types";
import { Photo } from "@models"

export interface PhotoState {
  entities: Photo[]
  trash: Photo[]
  selectedPhotoUid: string | null
}

const initialState: PhotoState = {
  entities: [],
  trash: [],
  selectedPhotoUid: null
};

export function photoReducer(state: PhotoState = initialState, action: PhotoActionTypes): PhotoState {
  switch (action.type) {
    case SET_PHOTOS:
      return {...state,
      entities: action.payload
    }
    case SET_TRASH:
      return {
        ...state,
        trash: action.payload
      }
    case SET_SELECTED_PHOTO_UID:
      return {...state,
      selectedPhotoUid: action.payload
    }
    default:
      return state;
  }
}
