import { PhotoFile } from '@models'

export const SET_FILES = '[FILE] SET_FILES'
export const REMOVE_FILE_BY_INDEX = '[FILE] REMOVE_FILE_BY_INDEX'
export const RESET = '[FILE] RESET'

export interface SetFilesAction {
  type: typeof SET_FILES;
  payload: PhotoFile[];
}

export interface RemoveFileByIndexAction {
  type: typeof REMOVE_FILE_BY_INDEX;
  payload: number;
}

export interface ResetAction {
  type: typeof RESET;
}

export type FileActionTypes = SetFilesAction | RemoveFileByIndexAction | ResetAction
