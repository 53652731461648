import { AppThunk } from '@redux'
import { checkoutResource } from '@resources'
import {
  SET_CLIENT_SECRET,
  SetClientSecretAction,
  SET_CHECKOUT_LOADING,
  SetCheckoutLoadingAction,
} from './checkout.types'

class CheckoutActions {
  setLoading(loading: boolean): SetCheckoutLoadingAction {
    return {
      type: SET_CHECKOUT_LOADING,
      payload: loading,
    }
  }

  setClientSecret(secret: string): SetClientSecretAction {
    return {
      type: SET_CLIENT_SECRET,
      payload: secret,
    }
  }

  getClientSecret = (amount: number): AppThunk => async (dispatch) => {
    dispatch(this.setLoading(true))
    const result = await checkoutResource.createPaymentIntent(amount)
    dispatch(this.setLoading(false))
    return result
  }
}

export const checkoutActions = new CheckoutActions()
