import { SET_CATEGORIES, SetCategoriesAction } from './category.types'
import { Category } from '@models'

class CategoryActions {
  setCategories(categories: Category[]): SetCategoriesAction {
    return {
      type: SET_CATEGORIES,
      payload: categories,
    }
  }
}

export const categoryActions = new CategoryActions()
