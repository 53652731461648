import { ContentSection, ContentBlock } from '@models'

class ContentService {
  contentBlockFactory(type: string): ContentBlock {
    return {
      type,
      backgroundColor: '',
      blockAddress: '',
      fontColor: '',
      mobileOrder: null,
      heading: '',
      content: '',
      photoUid: '',
      order: 0,
      url: '',
      blocks: [],
    }
  }

  contentSectionFactory(type: string): ContentSection {
    let blockCount = 0
    switch (type) {
      case 'full-width':
        blockCount = 1
        break
      case '2-blocks-left':
      case '2-blocks-right':
      case '2-blocks-even':
        blockCount = 2
        break
      case '3-blocks-even':
      case '3-blocks-left':
      case '3-blocks-right':
        blockCount = 3
        break
      case '4-blocks-even':
        blockCount = 4
        break
    }
    const blocks: ContentBlock[] = []
    for (let index = 0; index < blockCount; index++) {
      const block = this.contentBlockFactory('container')
      blocks.push(block)
    }
    return {
      type,
      backgroundColor: '',
      fontColor: '',
      order: 0,
      blocks,
    }
  }

  findBlockParent(sections: ContentSection[], block: ContentBlock) {
    const addressParts = block.blockAddress.split(',').map((n) => Number(n))
    let parent = sections[addressParts[0]]
    for (let index = 1; index < addressParts.length - 1; index++) {
      const address = addressParts[index]
      parent = parent.blocks[address]
    }
    return parent
  }

  setBlockOrderAndAddress = (block: ContentBlock, address: string[], order: number) => {
    block.order = order
    address.push(String(order))
    block.blockAddress = address.join(',')
    if (block.blocks.length) {
      for (let index = 0; index < block.blocks.length; index++) {
        block.blocks[index] = this.setBlockOrderAndAddress(block.blocks[index], [...address], index)
      }
    }
    return block
  }

  setOrderAndAddresses = (contentSections: ContentSection[]) => {
    for (let index = 0; index < contentSections.length; index++) {
      contentSections[index].order = index
      for (let b = 0; b < contentSections[index].blocks.length; b++) {
        contentSections[index].blocks[b] = this.setBlockOrderAndAddress(
          contentSections[index].blocks[b],
          [String(index)],
          b
        )
      }
    }
    return [...contentSections]
  }

  getBlockWidthPercent = (blockIndex: number, sectionType: string, containerWidth: number) => {
    const mediumWidth = 540
    const largeWidth = 920.69
    const xLargeWidth = 1080
    let blockWidthPercent = 100

    if (containerWidth >= mediumWidth) {
      switch (sectionType) {
        case '2-blocks-even':
          blockWidthPercent = 50
          break
        case '2-blocks-left':
          if (containerWidth < xLargeWidth) {
            blockWidthPercent = 50
          } else {
            blockWidthPercent = blockIndex === 0 ? 66.6666 : 33.3333
          }
          break
        case '2-blocks-right':
          if (containerWidth < xLargeWidth) {
            blockWidthPercent = 50
          } else {
            blockWidthPercent = blockIndex === 1 ? 66.6666 : 33.3333
          }
          break
        case '3-blocks-even':
          blockWidthPercent = containerWidth < largeWidth ? 100 : 33.3333
          break
        case '3-blocks-left':
          if (containerWidth < largeWidth) {
            blockWidthPercent = blockIndex === 0 ? 100 : 50
          } else {
            blockWidthPercent = blockIndex === 0 ? 50 : 25
          }
          break
        case '3-blocks-right':
        case '3-blocks-center':
          if (containerWidth < largeWidth) {
            blockWidthPercent = blockIndex < 2 && sectionType === '3-blocks-right' ? 50 : 100
          } else {
            blockWidthPercent = blockIndex === 1 ? 50 : 25
          }
          break
        case '4-blocks-even':
          blockWidthPercent = containerWidth < largeWidth ? 50 : 25
          break
      }
    }
    return blockWidthPercent
  }
}

export const contentService = new ContentService()
