import { Revision } from '@models'
import { utilityService } from '@services'

class RevisionService {
  sortByDate(revisions: Revision[]): Revision[] {
    return revisions.sort(
      (a, b) =>
        utilityService.parseTimestampToDate(b.createdAt).getTime() -
        utilityService.parseTimestampToDate(a.createdAt).getTime()
    )
  }

  factory(): Revision {
    return {
      name: '',
      publishAt: null,
      publishedAt: null,
      published: false,
      itemUid: '',
      type: 'page',
      createdAt: new Date(),
      createdByUid: '',
      contentSections: [],
      uid: '',
      updatedAt: new Date(),
      archivedAt: null,
      deletedAt: null,
    }
  }
}

export const revisionService = new RevisionService()
