import React, { ReactNode } from 'react'

interface LoaderPropTypes {
  inline?: boolean
  overlayClassName?: string
  show: boolean
  children?: ReactNode
}

export const Loader = (props: LoaderPropTypes) => {

  const { inline, show, children, overlayClassName } = props

  const renderContent = () => {
    return (
    <div className="loader-wrap">
        {children}
        <div className="jgp-folding-cube">
        <div className="jgp-cube1 jgp-cube"></div>
        <div className="jgp-cube2 jgp-cube"></div>
        <div className="jgp-cube4 jgp-cube"></div>
        <div className="jgp-cube3 jgp-cube"></div>
      </div>
    </div>
    )    
  }

  if(!show) {
    return null
  }

  if(inline) {
    return renderContent()
  }

  return (
    <div className={`overlay ${overlayClassName}`}>
      {renderContent()}
    </div>
  )

}