import { useDispatch } from 'react-redux'
import { blogResource } from '../resources'
import { Blog } from '@models'
import { blogActions } from '@redux'

export const useFirebaseBlogs = () => {
  const dispatch = useDispatch()

  blogResource.sync((blogs: Blog[]) => {
    dispatch(blogActions.setBlogs(blogs))
  })
}
