import React from 'react'
import { Link } from 'react-router-dom'
import { Nav } from '@components'

export const Header = () => {
  return (
    <header>
      <div className="header-wrap">
        <div className="header-logo-wrap">
          <Link to="/">
            <object
              data="/assets/logos/banner-logo.svg"
              type="image/svg+xml"
              className="header-logo"
              aria-label="header logo - Jon Good Photography"
            ></object>
          </Link>
        </div>
        <div className="header-nav-wrap">
          <Nav position="header"></Nav>
        </div>
      </div>
    </header>
  )
}
