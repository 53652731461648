import { Message } from '@models'

export const SET_SENT = '[MESSAGE] SET_SENT'
export const SET_MESSAGE = '[MESSAGE] SET_MESSAGE'


export interface SetMessageAction {
  type: typeof SET_MESSAGE
  payload: Message
}

export interface SetSentAction {
  type: typeof SET_SENT
  payload: boolean
}


export type MessageActionTypes = SetMessageAction | SetSentAction
