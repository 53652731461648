import { SET_CATEGORIES, CategoryActionTypes } from './category.types'
import { Category } from '@models'

export interface CategoryState {
  entities: Category[];
}

const initialState: CategoryState = {
  entities: [],
}

export function categoryReducer(state: CategoryState = initialState, action: CategoryActionTypes): CategoryState {
  switch (action.type) {
    case SET_CATEGORIES:
      return { ...state, entities: action.payload }
    default:
      return state
  }
}
