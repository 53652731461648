import React, { useState, useEffect } from 'react'
import { Photo } from '@models'
import { PhotoCard } from '../photo-card/photo-card.component'
import { Loader } from '../loader/loader.component'
import { useSelector, useDispatch } from 'react-redux'
import { pageSelectors, pageActions } from '@redux'

interface GridDimensions {
  width: number
  aspectRatio: number
  maxRowHeight: number
}

interface PhotoGridProps {
  photos: Photo[]
  margin: number
  maxWidth: number
}

interface PhotoRow {
  height: number
  items: Photo[]
}

export const PhotoGrid = ({photos, maxWidth, margin}: PhotoGridProps) => {

  const dispatch = useDispatch()
  const fromPhotoPage = useSelector(pageSelectors.getFromPhotoPage)
  const [rows, setRows] = useState<PhotoRow[]>([])
  const [gridWidth, setGridWidth] = useState(0)
  const isServer = (typeof window === 'undefined') ? true : false

  useEffect(() => {

    const listener = () => {      
      setGridDimensions()
    }
    if (!isServer) {
      setGridDimensions()  
      window.addEventListener('resize', listener)
    }
    return () => {
      dispatch(pageActions.setFromPhotoPage(false))
      window.removeEventListener('resize', listener)
    }
  }, [maxWidth, photos])

  const setGridDimensions = () => {
      const elementWidth = document.getElementById('grid-container')?.offsetWidth || 0
      const newWidth = elementWidth < maxWidth ? elementWidth : maxWidth
      let newMaxRowHeight = 225
      if(newWidth >= 330) {
        newMaxRowHeight = 400
      }
      if(newWidth >= 1600) {
        newMaxRowHeight = 550
      }

      const dimensions: GridDimensions = {
        width: newWidth,
        maxRowHeight: newMaxRowHeight,
        aspectRatio: newWidth / newMaxRowHeight
      }
      setGridWidth(newWidth)
      buildGrid(dimensions)
  }

  const getPhotoApsectRatio = (photo: Photo, margin: number) => (photo.width + margin) / (photo.height + margin)

  const buildRow = (items: Photo[], {width, maxRowHeight, aspectRatio}: GridDimensions): PhotoRow => {
    let totalAspectRatio: number = 0
    const row: PhotoRow = {height: 0, items: []}
    
    while(items.length > 0 && totalAspectRatio <= aspectRatio) {
      const item = items.shift()

      if(item) {
        totalAspectRatio += getPhotoApsectRatio(item, margin * 2)   
        row.items.push(item)
      }
    }    
    
    row.height = (row.items.length === 1 && items.length === 0) ? maxRowHeight / totalAspectRatio : (width - ((margin * 2 * row.items.length)-4)) / totalAspectRatio
    
    return row
  }

 const buildGrid = (dimensions: GridDimensions) => {   
    const grid: PhotoRow[] = []
    const photosCopy: Photo[] = photos.map(photo => photo)
    while (photosCopy.length > 0) {
      grid.push(buildRow(photosCopy, dimensions))      
    }     
    setRows(grid)
 }


  const renderGrid = () => {
    return (
      <div className={`grid${fromPhotoPage ? ' no-fade' : ''}`} style={{width: gridWidth + 'px'}}>
        {rows.map((row, r) => (
        <div className="photo-row" key={r}>
          {row.items.map((photo, c) => {
            const classNames: string[] = []
            if(r === 0) {
              classNames.push('top')
            }
            if(c === 0) {
              classNames.push('left')
            }
            if(r === rows.length - 1) {
              classNames.push('end')
            }
            if(c === row.items.length -1) {
              classNames.push('right')
            }
            return (<PhotoCard classNames={classNames} photo={photo} height={row.height + 'px'} width={photo.width / (photo.height / row.height) + 'px'} key={c} screenWidth={gridWidth}></PhotoCard>)
          })}
        </div> 
        ))} 
      </div>
    )
  }

  if(isServer) {
    return (
      <div className="grid" style={{width: '100%'}}>
        <Loader show={true} overlayClassName="under">Loading...</Loader>
      </div>
    )
  }

  return renderGrid()

}
