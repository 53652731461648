import { Tag } from '@models'
import { TagActionTypes } from '@redux'
import { SET_TAGS } from './tag.types'

export interface TagState {
  entities: Tag[];
}

const initialState: TagState = {
  entities: [],
}

export function tagReducer(state: TagState = initialState, action: TagActionTypes): TagState {
  switch (action.type) {
    case SET_TAGS:
      return { ...state, entities: action.payload }
    default:
      return state
  }
}
