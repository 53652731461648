import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, pageSelectors, pageActions } from '@redux'
import { PageHelmet, ContactForm } from '@components'
import { Redirect } from 'react-router-dom'

export const ContactPage = () => {
  const page = useSelector((state: RootState) => pageSelectors.getPageBySlug(state, 'contact'))
  const dispatch = useDispatch()
  dispatch(pageActions.setFromPhotoPage(false))

  if (!page) {
    return <Redirect to="/error"></Redirect>
  }

  return (
    <div className="page">
      <PageHelmet page={page}></PageHelmet>
      <div className="contact-page-content">
        <div className="row">
          <h1>Contact</h1>
        </div>
        <div className="row">
          <p>
            Contact me via{' '}
            <a href="https://www.facebook.com/JonGoodPhoto/" target="_blank" rel="noopener noreferrer">
              Facebook
            </a>{' '}
            or{' '}
            <a href="https://www.instagram.com/jongood01/" target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
            . Or fill out the form below to send me a message.
          </p>
        </div>
        <div className="row">
          <ContactForm></ContactForm>
        </div>
      </div>
    </div>
  )
}
