import { Photo } from '@models'
export const SET_PHOTOS = '[PHOTO] SET_PHOTOS'
export const SET_TRASH = '[PHOTO] SET_TRASH'
export const SET_SELECTED_PHOTO_UID = '[PHOTO] SET_SELECTED_PHOTO_UID'
export const SAVE_PHOTO = '[PHOTO] SAVE_PHOTO'
export const UPDATE_PHOTO = '[PHOTO] UPDATE_PHOTO'

export interface SetPhotosAction {
  type: typeof SET_PHOTOS;
  payload: Photo[];
}

export interface SetTrashAction {
  type: typeof SET_TRASH
  payload: Photo[]
}

export interface SetSelectedPhotoUidAction {
  type: typeof SET_SELECTED_PHOTO_UID;
  payload: string | null;
}

export interface SavePhotoAction {
  type: typeof SAVE_PHOTO;
  payload: Photo;
}

export interface UpdatePhotoAction {
  type: typeof UPDATE_PHOTO;
  payload: Photo;
}

export type PhotoActionTypes = SetPhotosAction | SetTrashAction | SetSelectedPhotoUidAction | SavePhotoAction | UpdatePhotoAction
