import { useDispatch } from 'react-redux'
import { pageResource } from '../resources'
import { Page } from '@models'
import { pageActions } from '@redux'

export const useFirebasePages = () => {
  const dispatch = useDispatch()

  pageResource.sync((pages: Page[]) => {
    dispatch(pageActions.setPages(pages))
  })
}
