import { Blog } from '@models'
export const SET_BLOGS = '[BLOG] SET_BLOGS'
export const SET_BLOG_LOADING = '[BLOG] SET_LOADING'

export interface SetBlogsAction {
  type: typeof SET_BLOGS;
  payload: Blog[];
}

export interface SetBlogLoadingAction {
  type: typeof SET_BLOG_LOADING;
  payload: boolean;
}

export type BlogActionTypes = SetBlogsAction | SetBlogLoadingAction
