import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { pageActions, pageSelectors } from '@redux'
import { Nav } from '@components'

export const MobileNav = () => {
  const dispatch = useDispatch()
  const expanded = useSelector(pageSelectors.getMobileNavExpanded)

  return (
    <div className="mobile-nav-wrap">
      <div
        className={`mobile-nav-overlay ${expanded ? 'expanded' : ''}`}
        onClick={() => dispatch(pageActions.setMobileNavExpanded(false))}
      ></div>
      <div className={`mobile-nav ${expanded ? 'expanded' : ''}`}>
        <Nav position="mobile"></Nav>
      </div>
    </div>
  )
}
