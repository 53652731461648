import React from 'react'
import { ProtectedRoute } from '../'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import {
  AboutPage,
  ContactPage,
  GalleryPage,
  NotFoundPage,
  PhotoPage,
  HomePage,
  ErrorPage,
  CustomPage,
  BlogPage,
  CheckoutPage,
} from '@pages'
import { Header, Footer } from '../../components'
import { useSelector } from 'react-redux'
import { userSelectors } from '../../redux/user'
import loadable from '@loadable/component'
import { MobileNav } from '../mobile-nav/mobile-nav.component'

export const AppRoutes = () => {
  const isAuthenticated = useSelector(userSelectors.isAuthenticated)
  const LoginPage = loadable(() => import('../../pages/login/login.page'))
  const AdminPage = loadable(() => import('../../admin/pages/admin/admin.page'))
  const location = useLocation()
  return (
    <>
      <Header></Header>
      <Switch>
        <Route path="/about" exact component={AboutPage}></Route>
        <ProtectedRoute
          path="/admin"
          isAuthenticated={isAuthenticated}
          component={AdminPage}
          redirect={`/login?redirect=${location.pathname}`}
        ></ProtectedRoute>
        <Route path="/contact" exact component={ContactPage}></Route>
        <Route path="/login" exact component={LoginPage}></Route>
        <Route path="/404" exact component={NotFoundPage}></Route>
        <Route path="/error" exact component={ErrorPage}></Route>
        <Route path="/gallery/:category" exact component={GalleryPage}></Route>
        <Route path="/blog/:slug" exact component={BlogPage}></Route>
        <Route path="/photos/:id" exact component={PhotoPage}></Route>
        <Route path="/pages/:slug" exact component={CustomPage}></Route>
        <Route path="/store/checkout" exact component={CheckoutPage}></Route>
        <Route path="/" exact component={HomePage}></Route>
        <Redirect to="/404" />
      </Switch>
      <MobileNav></MobileNav>
      <Footer></Footer>
    </>
  )
}
