import { useDispatch } from 'react-redux'
import { photoResource } from '../resources'
import { photoActions } from '../redux/photo'
import { Photo } from '@models'

export const useFirebasePhotos = () => {
  const dispatch = useDispatch()

  photoResource.sync((photos: Photo[]) => {
    dispatch(photoActions.setPhotos(photos))
  })
}

export const useFirebasePhotosTrash = () => {
  const dispatch = useDispatch()

  photoResource.syncTrash((photos: Photo[]) => {
    dispatch(photoActions.setTrash(photos))
  })
}
