import { FormState } from '../form.interfaces'

export const contactFormFieldsFactory = (): FormState => {
  return {
    name: {
      pristine: true,
      errors: [],
      validation: {
        required: { val: true, msg: 'You need to enter your name' },
        minLength: { val: 6, msg: 'Your name needs to be at least 6 characters' },
      },
    },
    email: {
      pristine: true,
      errors: [],
      validation: {
        required: { val: true, msg: 'You need to enter your email address' },
        pattern: { val: 'email', msg: 'You need to enter a valid email address' },
      },
    },
    subject: {
      pristine: true,
      errors: [],
      validation: {
        required: { val: true, msg: 'You need to enter a subject' },
        minLength: { val: 4, msg: 'Your subject must be at least 4 characters' },
      },
    },
    text: {
      pristine: true,
      errors: [],
      validation: {
        required: { val: true, msg: 'You need to enter a message' },
        minLength: { val: 10, msg: 'Your message needs to be at least 10 characters' },
        maxLength: { val: 250, msg: "Your message can't be more than 250 characters" },
      },
    },
  }
}
