import React, { FormEvent, useState } from 'react'

interface LoginFormProps {
  login: (email: string, password: string) => void
  message: string
}

export const LoginForm = (props: LoginFormProps) => {

  const {login, message} = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleEmailChange = (e: FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value)
  }

  const handlePasswordChange = (e: FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPassword('')
    await login(email, password)
  }

  return (
    <div className="form-wrap">
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-row">
          <label htmlFor="email">E-mail</label>
          <input type="email" value={email} name="email" onChange={handleEmailChange} />
        </div>
        <div className="form-row">
          <label htmlFor="password">Password</label>
          <input type="password" value={password} name="password" id="password" onChange={handlePasswordChange}/>
        </div>
        <div className="form-row">
          <button type="submit" disabled={!email || !password}>Login</button>
        </div>
        <p className="message-error">{message}</p>
      </form>
    </div>
  )
}