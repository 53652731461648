import {
  SET_LOADING,
  PageActionTypes,
  SET_CAN_GO_BACK,
  SET_PAGES,
  SET_MOBILE_NAV_EXPANDED,
  SET_ROUTE_CHANGED,
  SET_FROM_PHOTO_PAGE,
} from './page.types'
import { Page } from '@models'

export interface PageState {
  loading: boolean;
  canGoBack: boolean;
  mobileNavExpanded: boolean;
  entities: Page[];
  routeChanged: boolean;
  fromPhotoPage: boolean;
}

const initialState: PageState = {
  loading: false,
  canGoBack: false,
  mobileNavExpanded: false,
  entities: [],
  routeChanged: false,
  fromPhotoPage: false,
}

export function pageReducer(state: PageState = initialState, action: PageActionTypes): PageState {
  switch (action.type) {
    case SET_PAGES:
      return {
        ...state,
        entities: action.payload,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_CAN_GO_BACK:
      return {
        ...state,
        canGoBack: action.payload,
      }
    case SET_MOBILE_NAV_EXPANDED:
      return {
        ...state,
        mobileNavExpanded: action.payload,
      }
    case SET_ROUTE_CHANGED:
      return {
        ...state,
        routeChanged: action.payload,
      }
    case SET_FROM_PHOTO_PAGE:
      return {
        ...state,
        fromPhotoPage: action.payload,
      }
    default:
      return state
  }
}
