import { RootState } from '..'

class PhotoSelectors {
  getPhotos = ({ photo }: RootState) => photo.entities

  getTrash = ({ photo }: RootState) => photo.trash

  getSelectedPhotoUid = ({ photo }: RootState) => photo.selectedPhotoUid

  getSelectedPhoto = ({ photo }: RootState) => photo.entities.find((p) => p.uid === photo.selectedPhotoUid)

  getPhotoByUid = ({ photo }: RootState, uid: string) => photo.entities.find((p) => p.uid === uid)

  getTrashedPhotoByUid = ({ photo }: RootState, uid: string) => photo.trash.find((p) => p.uid === uid)

  getPhotosByCategory = ({ photo }: RootState, categorySlug: string) =>
    photo.entities.filter((p) => p.categories.find((c) => c.category === categorySlug))
}
export const photoSelectors = new PhotoSelectors()
