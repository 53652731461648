import { SET_SENT, SET_MESSAGE, SetSentAction, SetMessageAction } from './message.types'
import { Message } from '@models'
import { AppThunk } from '../setup'
import { messageResource } from '@resources'
import { messageService } from '@services'

class MessageActions {
  setSent(sent: boolean): SetSentAction {
    return {
      type: SET_SENT,
      payload: sent,
    }
  }

  setMessage(message: Message): SetMessageAction {
    return {
      type: SET_MESSAGE,
      payload: message,
    }
  }

  sendMessage = (message: Message, token: string): AppThunk<void> => async (dispatch) => {
    const text = ` [Email]: ${message.email} | [Name]: ${message.name} | [Message]: ${message.message.text}`
    const msg = { ...message }

    msg.message = { ...message.message, text }
    await messageResource.send(msg, token)
    dispatch(this.setSent(true))
    dispatch(this.setMessage(messageService.factory()))
  }
}

export const messageActions = new MessageActions()
