import { Page } from '@models'

class PageService {
  factory(): Page {
    return {
      slug: '',
      title: '',
      createdAt: new Date(),
      createdByUid: '',
      metaTitle: '',
      metaDescription: '',
      ogTitle: '',
      ogDescription: '',
      ogType: '',
      ogUrl: '',
      ogImage: '',
      contentSections: [],
      revisionUid: '',
      published: false,
      publishedAt: null,
      uid: '',
      updatedAt: new Date(),
      archivedAt: null,
      deletedAt: null,
    }
  }
}

export const pageService = new PageService()
