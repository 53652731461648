import {firestore} from './firebase.resource'
import { Page } from '@models'

class PageResource {
  public index: string = 'pages'
  private collection = firestore.collection(this.index)

  map(result: any) {
    return result.docs.map(doc => {
      const data = {...doc.data()}
      delete data['createdByUid']
      return data
    }) as Page[]
  }

  sync(callback: (pages: Page[] ) => void) {
    this.collection.onSnapshot(result => callback(this.map(result)))
  }

  save(page: Page): Promise<void> {
    page.updatedAt = new Date()
    return this.collection.doc(`/${page.uid}`).update(page)
  }

  async create(page: Page): Promise<string> {
    page.updatedAt = new Date()
    const newPage = await this.collection.add(page)
    await this.collection.doc(newPage.id).update({uid: newPage.id})
    return newPage.id
  }

  load(): Promise<Page[]> {
    return new Promise((resolve, reject) => {
      this.collection.onSnapshot(
        (result) => {
          resolve(this.map(result))
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}

export const pageResource = new PageResource()
