import { useEffect } from 'react'

export const useEscapeToClose = (onClose: () => void) => {
  if (typeof document === 'undefined') {
    return
  }

  const escapeKeyListener = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escapeKeyListener)

    return () => {
      document.removeEventListener('keydown', escapeKeyListener)
    }
  }, [])
}
