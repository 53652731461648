import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, pageSelectors, pageActions } from '@redux'
import { PageHelmet, Content } from '@components'

export const NotFoundPage = () => {
  const page = useSelector((state: RootState) => pageSelectors.getPageBySlug(state, 'not-found'))
  const dispatch = useDispatch()
  dispatch(pageActions.setFromPhotoPage(false))

  if (!page) {
    return (
      <div className="page">
        <h1>Oh dear something happened</h1>
      </div>
    )
  }

  return (
    <div className="page">
      <PageHelmet page={page}></PageHelmet>
      <Content sections={page.contentSections}></Content>
    </div>
  )
}
