import { SET_BLOGS, BlogActionTypes, SET_BLOG_LOADING } from './blog.types'
import { Blog } from '@models'

export interface BlogState {
  entities: Blog[];
  loading: boolean;
}

const initialState: BlogState = {
  entities: [],
  loading: false,
}

export function blogReducer(state: BlogState = initialState, action: BlogActionTypes): BlogState {
  switch (action.type) {
    case SET_BLOGS:
      return { ...state, entities: action.payload }
    case SET_BLOG_LOADING:
      return { ...state, loading: action.payload }
    default:
      return state
  }
}
