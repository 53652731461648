import { Revision } from '@models'
import { SetRevisionsAction, SetRevisionLoadingAction, AppThunk } from '@redux'
import { SET_REVISIONS, SET_REVISION_LOADING } from './revision.types'
import { revisionResource } from '@resources'

class RevisionActions {
  setRevisions(revisions: Revision[]): SetRevisionsAction {
    return {
      type: SET_REVISIONS,
      payload: revisions,
    }
  }

  setLoading(loading: boolean): SetRevisionLoadingAction {
    return {
      type: SET_REVISION_LOADING,
      payload: loading,
    }
  }

  updateRevision = (revision: Revision): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const revisions = state.revision.entities.map((r) => {
      if (r.uid === revision.uid) {
        return revision
      }
      return r
    })
    dispatch(this.setRevisions(revisions))
  }

  createRevision = (revision: Revision): AppThunk => async (dispatch, getState) => {
    dispatch(this.setLoading(true))
    const state = getState()
    revision.createdByUid = state.user.authUserUid
    const result = await revisionResource.create(revision)
    dispatch(this.setLoading(false))
    return result
  }

  saveRevision = (revision: Revision): AppThunk => async (dispatch) => {
    dispatch(this.setLoading(true))
    const result = await revisionResource.save(revision)
    dispatch(this.setLoading(false))
    return result
  }
}

export const revisionActions = new RevisionActions()
