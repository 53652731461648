import {firestore} from './firebase.resource'
import { Category } from '@models'

class CategoryResource {
  public index: string = 'categories'
  private collection = firestore.collection(this.index)

  map(result: any) {
    return result.docs.map(doc => {
      const data = {...doc.data()}
      delete data['createdByUid']
      return data
    }) as Category[]
  }
  
  sync(callback: (categories: Category[] ) => void) {
    this.collection.onSnapshot(result => callback(this.map(result)))
  }

  load(): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      this.collection.onSnapshot(
        (result) => {          
          resolve(this.map(result))
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

}

export const categoryResource = new CategoryResource()
