import { SET_TAGS, SetTagsAction } from './tag.types'
import { Tag } from '@models'
import { AppThunk } from '@redux'
import { firestore } from '@resources'

class TagActions {
  setTags(tags: Tag[]): SetTagsAction {
    return {
      type: SET_TAGS,
      payload: tags,
    }
  }

  addTag = (tag: Tag): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const tags = state.tag.entities.map((e) => e)
    dispatch(this.setTags(tags))
    await firestore
      .collection('tags')
      .doc(tag.slug)
      .set(tag)
  }
}

export const tagActions = new TagActions()
