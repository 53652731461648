import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { userSelectors, userActions } from '../../redux/user'
import { Link, useLocation } from 'react-router-dom'
import { pageActions } from '@redux'

interface NavProps {
  position: string;
}

export const Nav = ({ position }: NavProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const authUser = useSelector(userSelectors.getAuthUser)
  const path = location.pathname

  const handleLogout = () => {
    dispatch(userActions.logout())
  }

  const renderHomeLink = () => {
    if (position === 'header') {
      return null
    }
    return (
      <li className={path === '/' ? 'active' : ''}>
        <Link to="/">Home</Link>
      </li>
    )
  }

  const renderAuth = () => {
    if (!authUser || (authUser && position !== 'header')) {
      return null
    }
    return (
      <>
        <li className={path === '/admin' ? 'active' : ''}>
          <Link to="/admin">Admin</Link>
        </li>
        <li>
          <Link to="#" onClick={handleLogout}>
            Logout
          </Link>
        </li>
      </>
    )
  }

  return (
    <div className={`nav ${position}`}>
      <ul className="menu" onClick={() => dispatch(pageActions.setMobileNavExpanded(false))}>
        {renderHomeLink()}
        <li className={path === '/gallery/people' ? 'active' : ''}>
          <Link to="/gallery/people">People</Link>
        </li>
        <li className={path === '/gallery/places' ? 'active' : ''}>
          <Link to="/gallery/places">Places</Link>
        </li>
        <li className={path === '/gallery/things' ? 'active' : ''}>
          <Link to="/gallery/things">Things</Link>
        </li>
        <li className={path === '/contact' ? 'active' : ''}>
          <Link to="/contact">Contact</Link>
        </li>
        <li className={path === '/about' ? 'active' : ''}>
          <Link to="/about">About</Link>
        </li>
        {renderAuth()}
      </ul>
    </div>
  )
}
