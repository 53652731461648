import { useDispatch } from 'react-redux'
import { revisionResource } from '../resources'
import { Revision } from '@models'
import { revisionActions } from '@redux'

export const useFirebaseRevisions = () => {
  const dispatch = useDispatch()

  revisionResource.sync((revisions: Revision[]) => {
    dispatch(revisionActions.setRevisions(revisions))
  })
}
