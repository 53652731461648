import React from 'react'
import Helmet from 'react-helmet'
import { Page, Blog } from '@models'

interface PageHelmetProps {
  page: Page | Blog;
}

export const PageHelmet = ({ page }: PageHelmetProps) => {
  return (
    <Helmet>
      <title>{page?.metaTitle}</title>
      <meta name="description" content={page.metaDescription} />
      <meta property="og:title" content={page.ogTitle || page.metaTitle} />
      <meta property="og:type" content={page.ogType} />
      <meta property="og:url" content={page.ogUrl} />
      <meta property="og:image" content={page.ogImage} />
      <meta property="og:site_name" content="Jon Good Photography" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:description" content={page.ogDescription || page.metaDescription} />
    </Helmet>
  )
}
