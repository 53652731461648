import { RootState } from '..'

class BlogSelectors {
  getBlogs = ({ blog }: RootState) => blog.entities

  getBlogByUid = ({ blog }: RootState, uid: string) => blog.entities.find((b) => b.uid === uid)

  getLoading = ({ blog }: RootState) => blog.loading
}
export const blogSelectors = new BlogSelectors()
