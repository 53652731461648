import { UserActionTypes, SET_USERS, SET_AUTH_USER_UID} from "./user.types";
import { User } from "@models"

export interface UserState {
  entities: User[]
  authUserUid: string | null
}

const initialState: UserState = {
  entities: [],
  authUserUid: null
};

export function userReducer(state: UserState = initialState, action: UserActionTypes): UserState {
  switch (action.type) {
    case SET_USERS:
      return {...state,
      entities: action.payload
    }
    case SET_AUTH_USER_UID:
      return {...state,
      authUserUid: action.payload
    }
    default:
      return state;
  }
}
