import { Tag } from '@models'

class TagService {
  generateSlug(name: string) {
    return name
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/&/g, 'and')
  }

  factory(name: string): Tag {
    return {
      name,
      slug: this.generateSlug(name),
    }
  }
}

export const tagService = new TagService()
