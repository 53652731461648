import { FileActionTypes, SET_FILES, REMOVE_FILE_BY_INDEX, RESET } from './file.types'
import { PhotoFile } from '@models'

export interface FileState {
  entities: PhotoFile[];
}

const initialState: FileState = {
  entities: [],
}

export function fileReducer(state: FileState = initialState, action: FileActionTypes): FileState {
  switch (action.type) {
    case SET_FILES:
      return { ...state, entities: action.payload }
    case REMOVE_FILE_BY_INDEX:
      const newFiles = state.entities.map((file) => file)
      newFiles.splice(action.payload, 1)
      return { ...state, entities: newFiles }
    case RESET:
      return initialState
    default:
      return state
  }
}
