export const SET_CHECKOUT_LOADING = '[CHECKOUT] SET_LOADING'
export const SET_CLIENT_SECRET = '[CHECKOUT] SET_CLIENT_SECRET'

export interface SetClientSecretAction {
  type: typeof SET_CLIENT_SECRET
  payload: string
}

export interface SetCheckoutLoadingAction {
  type: typeof SET_CHECKOUT_LOADING
  payload: boolean
}

export type CheckoutActionTypes = SetCheckoutLoadingAction | SetClientSecretAction
