import { RootState } from '..'

class PageSelectors {
  getPages = ({ page }: RootState) => page.entities

  getLoading = ({ page }: RootState) => page.loading

  getCanGoBack = ({ page }: RootState) => page.canGoBack

  getPageBySlug = ({ page }: RootState, slug: string) => page.entities.find((p) => p.slug === slug)

  getPageByUid = ({ page }: RootState, uid: string) => page.entities.find((p) => p.uid === uid)

  getMobileNavExpanded = ({ page }: RootState) => page.mobileNavExpanded

  getRouteChanged = ({ page }: RootState) => page.routeChanged

  getFromPhotoPage = ({ page }: RootState) => page.fromPhotoPage
}
export const pageSelectors = new PageSelectors()
