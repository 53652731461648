import { Photo } from '@models'

class PhotoService {
  sortDefault(photos: Photo[]) {
    return photos.sort((a, b) => a.defaultSort - b.defaultSort)
  }

  sortCategory(photos: Photo[], category: string) {
    return photos.sort((a, b) => {
      const categoryA = a.categories.find((c) => c.category == category)
      const categoryB = b.categories.find((c) => c.category == category)
      if (!categoryA || !categoryB) return 0
      return categoryA.order - categoryB.order
    })
  }

  factory(): Photo {
    return {
      archivedAt: null,
      categories: [],
      createdAt: new Date(),
      createdByUid: null,
      defaultSort: 0,
      deletedAt: null,
      description: '',
      fileName: '',
      height: 0,
      name: '',
      placeholderColor: '#999999',
      published: false,
      publishAt: null,
      sourceLarge: '',
      sourceMedium: '',
      sourceSmall: '',
      sourceTiny: '',
      tags: [],
      technicalDetail: {
        aperture: '5.6',
        focalLength: '50',
        iso: '100',
        shutterSpeed: '1/200',
      },
      uid: '',
      updatedAt: new Date(),
      width: 0,
    }
  }
}

export const photoService = new PhotoService()
