import { Revision } from '@models'
export const SET_REVISIONS = '[REVISION] SET_REVISIONS'
export const SET_REVISION_LOADING = '[REVISION] SET_LOADING'

export interface SetRevisionsAction {
  type: typeof SET_REVISIONS;
  payload: Revision[];
}

export interface SetRevisionLoadingAction {
  type: typeof SET_REVISION_LOADING;
  payload: boolean;
}

export type RevisionActionTypes = SetRevisionsAction | SetRevisionLoadingAction
