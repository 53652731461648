import React, { ReactNode, useEffect } from 'react'
import { useEscapeToClose } from '../../hooks'

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const Modal = ({ children, isOpen, onClose }: ModalProps) => {
  const overlayClassName = isOpen ? 'modal-overlay open' : 'modal-overlay'
  const contentClassName = isOpen ? 'modal-content open' : 'modal-content'

  const close = () => {
    document.body.style.overflow = 'unset'
    onClose()
  }

  useEscapeToClose(close)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <div id="overlay" className={overlayClassName} onClick={() => close()}>
      <div id="modal-content" onClick={(e) => e.stopPropagation()} className={contentClassName}>
        <div className="modal-content-children">{children}</div>
        <i className="material-icons modal-close-button" onClick={() => close()}>
          clear
        </i>
      </div>
    </div>
  )
}
