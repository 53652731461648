import { Revision } from '@models'
import { RevisionActionTypes } from '@redux'
import { SET_REVISIONS, SET_REVISION_LOADING } from './revision.types'

export interface RevisionState {
  loading: boolean;
  entities: Revision[];
}

const initialState: RevisionState = {
  loading: false,
  entities: [],
}

export function revisionReducer(state: RevisionState = initialState, action: RevisionActionTypes): RevisionState {
  switch (action.type) {
    case SET_REVISIONS:
      return { ...state, entities: action.payload }
    case SET_REVISION_LOADING:
      return { ...state, loading: action.payload }
    default:
      return state
  }
}
