import { Address } from '@models'

class AddressService {
  factory(): Address {
    return {
      archivedAt: null,
      city: '',
      country: 'New Zealand',
      countryCode: 'NZ',
      createdAt: new Date(),
      createdByUid: '',
      deletedAt: null,
      email: null,
      line1: '',
      line2: null,
      name: '',
      phone: null,
      postcode: '',
      suburb: null,
      uid: '',
      updatedAt: new Date(),
      userUid: '',
    }
  }
}

export const addressService = new AddressService()
