import { User } from "@models"
import { SET_USERS, SET_AUTH_USER_UID, SetUsersAction, SetAuthUserUidAction } from "./user.types"
import { AppThunk } from ".."
import { firestore, auth } from '@resources'

class UserActions {
  setUsers(users: User[]): SetUsersAction {
    return {
      type: SET_USERS,
      payload: users
    }
  }

  setAuthUserUid(uid: string): SetAuthUserUidAction {
    return {
      type: SET_AUTH_USER_UID,
      payload: uid
    }
  }

  loadUsers = (): AppThunk<void> => async (dispatch) => {    
    firestore.collection('users').onSnapshot(result => {
      const users = result.docs.map(doc => {return {uid: doc.id, ...doc.data()}} ) as User[]
      dispatch(this.setUsers(users))
    })
  }

  logout = (): AppThunk<void> => async dispatch => {
    auth.signOut().then(() => {
      dispatch(this.setUsers([]))
      dispatch(this.setAuthUserUid('')) 
    })
  }

}

export const userActions = new UserActions()