import { Photo, Tag } from '@models'
import {
  SET_PHOTOS,
  SET_SELECTED_PHOTO_UID,
  SetPhotosAction,
  SetSelectedPhotoUidAction,
  SetTrashAction,
  SET_TRASH,
} from './photo.types'
import { AppThunk } from '../setup'
import { photoResource } from '@resources'

class PhotoActions {
  setPhotos(photos: Photo[]): SetPhotosAction {
    return {
      type: SET_PHOTOS,
      payload: photos,
    }
  }

  setTrash(photos: Photo[]): SetTrashAction {
    return {
      type: SET_TRASH,
      payload: photos,
    }
  }

  setSelectedPhotoUid(uid: string): SetSelectedPhotoUidAction {
    return {
      type: SET_SELECTED_PHOTO_UID,
      payload: uid,
    }
  }

  savePhoto = (photo: Photo): AppThunk => () => {
    photo.updatedAt = new Date()
    return photoResource.save(photo)
  }

  updatePhoto = (photo: Photo): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const photos = state.photo.entities.map((p) => {
      if (p.uid === photo.uid) {
        return photo
      }
      return p
    })
    dispatch(this.setPhotos(photos))
  }

  addCategory = (photo: Photo, categorySlug: string): AppThunk => (dispatch) => {
    if (photo.categories.find((p) => p.category === categorySlug)) {
      return
    }
    const categories = photo.categories.map((p) => p) || []
    categories.push({ category: categorySlug, order: 0 })
    dispatch(this.updatePhoto({ ...photo, categories }))
  }

  removeCategory = (photo: Photo, categorySlug: string): AppThunk => (dispatch) => {
    if (!photo.categories.find((p) => p.category === categorySlug)) {
      return
    }
    const categories = photo.categories.map((p) => p).filter((p) => p.category !== categorySlug)
    dispatch(this.updatePhoto({ ...photo, categories }))
  }

  addTag = (photo: Photo, tag: Tag): AppThunk => (dispatch) => {
    if (photo.tags.find((t) => t.slug === tag.slug)) {
      return
    }
    const tags = photo.tags.map((p) => p) || []
    tags.push(tag)
    dispatch(this.updatePhoto({ ...photo, tags }))
  }

  removeTag = (photo: Photo, tag: Tag): AppThunk => (dispatch) => {
    if (!photo.tags.find((t) => t.slug === tag.slug)) {
      return
    }
    const tags = photo.tags.map((t) => t).filter((t) => t.slug !== tag.slug)
    dispatch(this.updatePhoto({ ...photo, tags }))
  }

  trash = (photo: Photo): AppThunk => (dispatch) => {
    const deletedAt = new Date()
    dispatch(this.savePhoto({ ...photo, deletedAt }))
  }

  restore = (photo: Photo): AppThunk => (dispatch) => {
    const deletedAt = null
    dispatch(this.savePhoto({ ...photo, deletedAt }))
  }

  archive = (photo: Photo): AppThunk => (dispatch) => {
    const archivedAt = new Date()
    dispatch(this.savePhoto({ ...photo, archivedAt }))
  }

  unarchive = (photo: Photo): AppThunk => (dispatch) => {
    const archivedAt = null
    dispatch(this.savePhoto({ ...photo, archivedAt }))
  }

  setDefaultSort = (photo: Photo, sort: number): AppThunk => (dispatch) => {
    dispatch(this.savePhoto({ ...photo, defaultSort: sort }))
  }

  setCategorySort = (photo: Photo, categorySlug: string, sort: number): AppThunk => (dispatch) => {
    const categories = photo.categories.map((c) => {
      if (c.category == categorySlug) {
        c.order = sort
      }
      return c
    })

    dispatch(this.savePhoto({ ...photo, categories }))
  }
}

export const photoActions = new PhotoActions()
