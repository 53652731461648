import { Message } from '@models'

class MessageService {
  factory(): Message {
    return {
      toUids: [],
      email: '',
      name: '',
      message: {
        subject: '',
        text: '',
      },
      createdAt: new Date(),
      createdByUid: 'anonymous',
      updatedAt: null,
      uid: '',
      deletedAt: null,
      archivedAt: null,
    }
  }
}

export const messageService = new MessageService()
