import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, pageSelectors, pageActions } from '@redux'
import { PageHelmet, Content } from '@components'
import { Redirect } from 'react-router-dom'

export const HomePage = () => {
  const dispatch = useDispatch()
  const page = useSelector((state: RootState) => pageSelectors.getPageBySlug(state, 'home'))

  useEffect(() => {
    dispatch(pageActions.setFromPhotoPage(false))
    dispatch(pageActions.setCanGoBack(true))
  }, [dispatch])

  if (!page) {
    return <Redirect to="/error"></Redirect>
  }

  return (
    <div className="page">
      <PageHelmet page={page}></PageHelmet>
      <Content sections={page.contentSections}></Content>
    </div>
  )
}
