import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import { Photo } from '@models'
import { PHOTO_BASE_PATH } from '@constants'

interface PhotoCardProps {
  classNames: string[]
  photo: Photo
  height: string
  width: string
  screenWidth: number
}

export const PhotoCard = ({classNames = [], photo, height, width, screenWidth}: PhotoCardProps) => {
  
  const [source, setSource] = useState('')

  useEffect(() => {
     setSource(PHOTO_BASE_PATH + photo.sourceSmall || '')
    
    if(screenWidth >= 1200) {
      setSource(PHOTO_BASE_PATH + photo.sourceMedium)
    }
  }, [screenWidth, photo, width, height, classNames])

  if(!source || !photo) return null

  return (
    <>
    <Link to={`/photos/${photo.uid}`}>
      <div className={`photo-card ${classNames.join(' ')}`} style={{height: height, width: width, backgroundColor: photo.placeholderColor}}>
        <img src={source} alt="" height={height} width={width} />
      </div>    
    </Link>
  </>
  )
}