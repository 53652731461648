import { SET_BLOGS, SetBlogsAction, SetBlogLoadingAction, SET_BLOG_LOADING } from './blog.types'
import { Blog, Revision } from '@models'
import { AppThunk } from '../setup'
import { blogResource } from '@resources'

class BlogActions {
  setBlogs(blogs: Blog[]): SetBlogsAction {
    return {
      type: SET_BLOGS,
      payload: blogs,
    }
  }

  setLoading(loading: boolean): SetBlogLoadingAction {
    return {
      type: SET_BLOG_LOADING,
      payload: loading,
    }
  }

  publishPage = (blog: Blog): AppThunk => async (dispatch) => {
    blog.published = true
    blog.publishedAt = new Date()
    dispatch(this.saveBlog({ ...blog }))
  }

  unpublishPage = (blog: Blog): AppThunk => async (dispatch) => {
    blog.published = false
    dispatch(this.saveBlog({ ...blog }))
  }

  createBlog = (blog: Blog): AppThunk => async (dispatch, getState) => {
    dispatch(this.setLoading(true))
    const state = getState()
    blog.createdByUid = state.user.authUserUid
    const result = await blogResource.create(blog)
    dispatch(this.setLoading(false))
    return result
  }

  saveBlog = (blog: Blog): AppThunk => async (dispatch) => {
    dispatch(this.setLoading(true))
    const result = await blogResource.save(blog)
    dispatch(this.setLoading(false))
    return result
  }

  updatePage = (blog: Blog): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const blogs = state.blog.entities.map((b) => {
      if (b.uid === blog.uid) {
        return blog
      }
      return b
    })
    dispatch(this.setBlogs(blogs))
  }

  applyRevision = (blog: Blog, revision: Revision): AppThunk => (dispatch) => {
    dispatch(this.saveBlog({ ...blog, contentSections: revision.contentSections, revisionUid: revision.uid || '' }))
  }
}

export const blogActions = new BlogActions()
