import { FormValidationRule, FormState } from '../components/form.interfaces'

class FormService {

  private emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

  /**
   * Validate a field based on validation configuration
   * @param value 
   * @param validation 
   */
  validateField(value: string, validation: FormValidationRule): string[] {
    
    if (!value && validation.required.val) {
      return [validation.required.msg]
    }
    if (validation.minLength && value.length < validation.minLength.val) {
      return [validation.minLength.msg]
    }
    if (validation.maxLength && value.length > validation.maxLength.val) {
      return [validation.maxLength.msg]
    }
    if (validation?.pattern?.val === 'email' && !value.match(this.emailRegex)) {
      return [validation.pattern.msg]
    }
    if (validation.pattern && validation.pattern?.val !== 'email' && !value.match(validation.pattern.val)) {
      return [validation.pattern.msg]
    }
    return []
  }

  /**
   * Validate a form
   * @param formState 
   */
  validateForm (formState: FormState): boolean {
    for (const field in formState) {
      if(formState[field].errors.length) {
        return false
      }
      if(formState[field].pristine && formState[field].validation?.required?.val) {
        return false
      }
    }
    return true
  }
}

export const formService = new FormService()
