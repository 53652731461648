import { createStore, combineReducers, applyMiddleware, Action } from 'redux'
import thunkMiddleware, { ThunkAction } from 'redux-thunk'
import { blogReducer } from './blog'
import { categoryReducer } from './category'
import { fileReducer } from './file'
import { pageReducer } from './page'
import { photoReducer } from './photo'
import { userReducer } from './user'
import { composeWithDevTools } from 'redux-devtools-extension'
import { messageReducer } from './message'
import { tagReducer } from './tag'
import { revisionReducer } from './revision'
import { checkoutReducer } from './checkout'

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export const rootReducer = combineReducers({
  blog: blogReducer,
  category: categoryReducer,
  checkout: checkoutReducer,
  file: fileReducer,
  message: messageReducer,
  page: pageReducer,
  photo: photoReducer,
  revision: revisionReducer,
  tag: tagReducer,
  user: userReducer,
})

export function configureStore(initialState: any, environment: string | undefined) {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer]
  const composedEnhancers = environment === 'production' ? middlewareEnhancer : composeWithDevTools(...enhancers)
  return createStore(rootReducer, initialState, composedEnhancers)
}

export type RootState = ReturnType<typeof rootReducer>
