import {firestore} from './firebase.resource'
import { Revision } from '@models'

class RevisionResource {
  public index: string = 'revisions'
  private collection = firestore.collection(this.index)

  map(result: any) {
    return result.docs.map(doc => {
      const data = {...doc.data()}
      delete data['createdByUid']
      return data
    }) as Revision[]
  }

  sync(callback: (revisions: Revision[] ) => void) {
    this.collection.onSnapshot(result => callback(this.map(result)))
  }

  async create(revision: Revision): Promise<void> {
    revision.updatedAt = new Date()
    const result = await this.collection.add(revision)
    revision.uid = result.id
    return this.save(revision)
  }

  save(revision: Revision): Promise<void> {
    revision.updatedAt = new Date()
    return this.collection.doc(`/${revision.uid}`).update(revision)
  }

  load(): Promise<Revision[]> {
    return new Promise((resolve, reject) => {
      this.collection.onSnapshot(
        (result) => {
          resolve(this.map(result))
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}

export const revisionResource = new RevisionResource()
