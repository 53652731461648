import React, { useEffect, useState } from 'react'
import { ContentSection, ContentBlock } from '@models'
import { useSelector } from 'react-redux'
import { photoSelectors } from '@redux'
import { PHOTO_BASE_PATH } from '@constants'
import { Link } from 'react-router-dom'
import { contentService } from '@services'

interface ContentProps {
  sections: ContentSection[];
}

export const Content = ({ sections }: ContentProps) => {
  const photos = useSelector(photoSelectors.getPhotos)
  const [containerWidth, setContainerWidth] = useState(0)
  const isServer = typeof window === 'undefined' ? true : false

  useEffect(() => {
    const listener = () => {
      setup()
    }
    if (!isServer) {
      setup()
      window.addEventListener('resize', listener)
    }
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [photos, sections])

  const setup = () => {
    const width = document.getElementById('content-container')?.offsetWidth || 0
    setContainerWidth(width)
  }

  const buildBlockStyle = (block: ContentBlock, overrideHeight: number = 0) => {
    let style: { [key: string]: string } = {}
    if (block.backgroundColor) {
      style.backGroundColor = block.backgroundColor
    }
    if (block.fontColor) {
      style.fontColor = block.fontColor
    }
    if (block.mobileOrder && containerWidth <= 540) {
      style.order = String(block.mobileOrder)
    }
    if (overrideHeight > 0) {
      style.height = `${overrideHeight}px`
    }
    return style
  }

  const getFirstPhotoBlockIndex = (section: ContentSection): number => {
    for (let index = 0; index < section.blocks.length; index++) {
      const block = section.blocks[index]
      if (block.type === 'photo' || block.type === 'embedded-photo') {
        return index
      }
    }
    return -1
  }

  const calculateBlockHeight = (section: ContentSection): number => {
    const photoBlockIndex = getFirstPhotoBlockIndex(section)
    if (photoBlockIndex < 0) return 0
    const photoBlock = section.blocks[photoBlockIndex]
    const photo = photos.find((p) => p.uid === photoBlock.photoUid)
    if (!photo) return 0

    const blockWidthPercent = contentService.getBlockWidthPercent(photoBlockIndex, section.type, containerWidth)
    const blockWidth = containerWidth * (blockWidthPercent / 100)
    return blockWidth / (photo.width / photo.height)
  }

  const buildLinkContent = (content: string, index: number = 0) => {
    const parts = content.split('///')
    return (
      <p key={index}>
        {parts.map((part) => {
          if (part.match(/\[\</)) {
            part = part.replace('[<', '')
            part = part.replace('>]', '')
            const linkParts = part.split('|')
            if (linkParts.length !== 2) return null
            if (linkParts[0].match('http')) {
              return (
                <a style={{ color: 'inherit' }} href={linkParts[0]} target="_blank">
                  {linkParts[1]}
                </a>
              )
            }
            return (
              <Link style={{ color: 'inherit' }} to={linkParts[0]}>
                {linkParts[1]}
              </Link>
            )
          }
          return part
        })}
      </p>
    )
  }

  const renderBlock = (block: ContentBlock, index: number, overrideHeight: number = 0, widthPercent: number = 0) => {
    const photo = photos.find((p) => p.uid === block.photoUid)
    if (block.type === 'container' && block.blocks.length) {
      const containerClassName = block.blocks.length >= 2 ? ' block-container' : ''
      return (
        <div key={index} className={`block${containerClassName}`} style={buildBlockStyle(block, overrideHeight)}>
          {block.blocks.map((subBlock, subBlockIndex) => renderBlock(subBlock, subBlockIndex))}
        </div>
      )
    }
    switch (block.type) {
      case 'heading':
        return (
          <div key={index} className="block heading" style={buildBlockStyle(block, overrideHeight)}>
            <h1>{block.heading}</h1>
          </div>
        )
      case 'paragraph':
        // This is a custom width to make it work with font size etc
        const paraHeight = containerWidth <= 740 ? 0 : overrideHeight
        return (
          <div key={index} className="block para" style={buildBlockStyle(block, paraHeight)}>
            {buildLinkContent(block.content)}
          </div>
        )
      case 'blockquote':
        return (
          <div key={index} className="block blockquote" style={buildBlockStyle(block, overrideHeight)}>
            <blockquote>{block.content}</blockquote>
          </div>
        )
      case 'post-content':
        const paragraphs = block.content.split('\n').filter((p) => p)
        return (
          <div key={index} className="block post-content" style={buildBlockStyle(block, overrideHeight)}>
            {paragraphs.map((para, i) => buildLinkContent(para, i))}
          </div>
        )
      case 'photo':
        if (!photo) {
          return <div key={index} className="block photo"></div>
        }
        overrideHeight = widthPercent === 100 ? containerWidth / (photo.width / photo.height) : overrideHeight
        const photoStyle = buildBlockStyle(block, overrideHeight)
        const photoSource = containerWidth <= 540 ? photo.sourceMedium : photo.sourceLarge
        photoStyle.backgroundImage = `url(${PHOTO_BASE_PATH + photoSource})`
        return (
          <div className="block photo" style={photoStyle}>
            <Link to={`/photos/${photo.uid}`}></Link>
          </div>
        )
      case 'embedded-photo':
        if (!photo) {
          return <div key={index} className="block photo"></div>
        }
        overrideHeight = widthPercent === 100 ? containerWidth / (photo.width / photo.height) : overrideHeight
        const embeddedStyle = buildBlockStyle(block, overrideHeight)
        const photoSource2 = containerWidth <= 540 ? photo.sourceMedium : photo.sourceLarge
        embeddedStyle.backgroundImage = `url(${PHOTO_BASE_PATH + photoSource2})`
        return <div className="block photo" style={embeddedStyle}></div>
      case 'heading-over-photo':
        return (
          <div
            key={index}
            className="block heading-photo"
            style={{
              backgroundColor: block.backgroundColor,
              color: block.fontColor,
              backgroundImage: `url(${PHOTO_BASE_PATH + photo?.sourceLarge})`,
            }}
          >
            <h1>{block.heading}</h1>
          </div>
        )
      case 'paragraph-over-photo':
        return (
          <div
            key={index}
            className="block para-photo"
            style={{
              backgroundColor: block.backgroundColor,
              color: block.fontColor,
              backgroundImage: `url(${PHOTO_BASE_PATH + photo?.sourceLarge})`,
            }}
          >
            {buildLinkContent(block.content)}
          </div>
        )
      case 'call-to-action':
        return (
          <div
            key={index}
            className="block cta"
            style={{
              backgroundColor: block.backgroundColor,
              color: block.fontColor,
            }}
          >
            <Link className="button" to={block.url}>
              {block.content}
            </Link>
          </div>
        )
      case 'call-to-action-external':
        return (
          <div
            key={index}
            className="block cta"
            style={{
              backgroundColor: block.backgroundColor,
              color: block.fontColor,
            }}
          >
            <a className="button" href={block.url} target="_blank">
              {block.content}
            </a>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div id="content-container">
      {sections.map((section, index) => {
        const overrideHeight = calculateBlockHeight(section)
        return (
          <section
            key={index}
            className={`content-section ${section.type
              .replace('2', 'two')
              .replace('3', 'three')
              .replace('4', 'four')}`}
            style={{ backgroundColor: section.backgroundColor, color: section.fontColor }}
          >
            {section.blocks.map((block, blockIndex) => {
              const blockWidthPercent = contentService.getBlockWidthPercent(blockIndex, section.type, containerWidth)
              return renderBlock(block, blockIndex, overrideHeight, blockWidthPercent)
            })}
          </section>
        )
      })}
    </div>
  )
}
