export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDV5Dmfg9i2q9gqfkBdbTeRXFgbL5VY8LE',
  authDomain: 'jon-good-photo.firebaseapp.com',
  databaseURL: 'https://jon-good-photo.firebaseio.com',
  projectId: 'jon-good-photo',
  storageBucket: 'jon-good-photo.appspot.com',
  messagingSenderId: '627079981644',
  appId: '1:627079981644:web:f50a0e33e622d13fca62e9',
  measurementId: 'G-SJF4QQWRHE',
}
