import { Page } from '@models'

export const SET_LOADING = '[PAGE] SET_LOADING'
export const SET_CAN_GO_BACK = '[PAGE] SET_CAN_GO_BACK'
export const SET_PAGES = '[PAGE] SET_PAGES'
export const SET_MOBILE_NAV_EXPANDED = '[PAGE] SET_MOBILE_NAV_EXPANDED'
export const SET_ROUTE_CHANGED = '[PAGE] SET_ROUTE_CHANGED'
export const SET_FROM_PHOTO_PAGE = '[PAGE] SET_FROM_PHOTO_PAGE'

export interface SetPagesAction {
  type: typeof SET_PAGES
  payload: Page[]
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetCanGoBackAction {
  type: typeof SET_CAN_GO_BACK
  payload: boolean
}

export interface SetMobileNavExpanded {
  type: typeof SET_MOBILE_NAV_EXPANDED
  payload: boolean
}

export interface SetRouteChanged {
  type: typeof SET_ROUTE_CHANGED
  payload: boolean
}

export interface SetFromPhotoPage {
  type: typeof SET_FROM_PHOTO_PAGE
  payload: boolean
}

export type PageActionTypes = SetPagesAction | SetLoadingAction | SetCanGoBackAction | SetMobileNavExpanded | SetRouteChanged | SetFromPhotoPage
