import { Message } from '@models'
import { functions } from './firebase.resource'

class MessageResource {
  async send(message: Message, token: string) {
    const sendMessage = functions.httpsCallable('sendMessage')
    return await sendMessage({ message, token })
  }
}

export const messageResource = new MessageResource()
