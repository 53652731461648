import {firestore} from './firebase.resource'
import { Blog } from '@models'

class BlogResource {
  public index: string = 'blogs'
  private collection = firestore.collection(this.index)

  map(result: any) {
    return result.docs.map(doc => {
      const data = {...doc.data()}
      delete data['createdByUid']
      return data
    }) as Blog[]
  }

  sync(callback: (blogs: Blog[] ) => void) {
    this.collection.onSnapshot(result => callback(this.map(result)))
  }

  save(blog: Blog): Promise<void> {
    blog.updatedAt = new Date()
    return this.collection.doc(`/${blog.uid}`).update(blog)
  }

  async create(blog: Blog): Promise<string> {
    blog.updatedAt = new Date()
    const newBlog = await this.collection.add(blog)
    await this.collection.doc(newBlog.id).update({uid: newBlog.id})
    return newBlog.id
  }

  load(): Promise<Blog[]> {
    return new Promise((resolve, reject) => {
      this.collection.onSnapshot(
        (result) => {
          resolve(this.map(result))
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}

export const blogResource = new BlogResource()
