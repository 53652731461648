import { functions } from './firebase.resource'

class CheckoutResource {
  async createPaymentIntent(amount: number) {
    const create = functions.httpsCallable('createStripePaymentIntent')
    return await create({ amount })
  }
}

export const checkoutResource = new CheckoutResource()
