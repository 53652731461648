import { RootState } from '..'
import { User } from '@models'

class UserSelectors {
  getUsers = (state: RootState): User[] => state.user.entities

  getAuthUserUid = (state: RootState): string | null => state.user.authUserUid

  getAuthUser = (state: RootState): User | undefined =>
    state.user.entities.find((user) => user.uid === state.user.authUserUid)

  isAuthenticated = (state: RootState): boolean => (state.user.authUserUid ? true : false)
}
export const userSelectors = new UserSelectors()
