import { Cart } from '@models'

class CartService {
  factory(): Cart {
    return {
      archivedAt: null,
      createdAt: new Date(),
      createdByUid: '',
      deletedAt: null,
      items: [],
      subtotal: 0,
      tax: 0,
      total: 0,
      uid: '',
      updatedAt: new Date(),
      userUid: '',
    }
  }
}

export const cartService = new CartService()
