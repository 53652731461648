import React, { FormEvent, useState, useEffect } from 'react'
import { recaptchaSiteKey } from '@constants'
import { useSelector, useDispatch } from 'react-redux'
import { messageActions, messageSelectors, pageActions, pageSelectors } from '@redux'
import { Loader } from '../loader/loader.component'
import { formService, messageService } from '@services'
import { MessageKeyTypes, MessageContentKeyTypes, ErrorModel } from '@models'
import { contactFormFieldsFactory } from './contact-form.state'

export const ContactForm = () => {
  const dispatch = useDispatch()
  const [formMessage, setFormMessage] = useState({ type: 'none', text: '' })
  const [formIsValid, setFormIsValid] = useState(false)
  const [formState, setFormState] = useState(contactFormFieldsFactory())
  const loading = useSelector(pageSelectors.getLoading)
  const sent = useSelector(messageSelectors.getSent)
  const message = useSelector(messageSelectors.getMessage)

  useEffect(() => {
    // Reset the state of the form when component mounts
    reset()
    setFormMessage({ type: 'none', text: '' })
  }, [])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!window?.grecaptcha || !formIsValid) {
      return null
    }
    dispatch(pageActions.setLoading(true))
    try {
      const token = await window.grecaptcha.execute(recaptchaSiteKey, { action: 'contactpage' })
      await dispatch(messageActions.sendMessage(message, token))
      dispatch(pageActions.setLoading(false))
      setFormMessage({ type: 'success', text: 'Success! Your message has been sent.' })
      reset()
    } catch (error) {
      handleError(error)
      dispatch(pageActions.setLoading(false))
    }

    return false
  }

  const reset = () => {
    setFormState(contactFormFieldsFactory())
    setFormIsValid(false)
  }

  const handleError = (error: ErrorModel) => {
    let errorMessage = 'There was an error sending your message. Please try again.'

    if (error.message === 'Recaptcha failed to verify') {
      errorMessage = "Looks like you could be a robot. If you're not you can reload and try again."
      dispatch(messageActions.setMessage(messageService.factory()))
      reset()
    }
    setFormMessage({ type: 'error', text: `Error! ${errorMessage}` })
  }

  const validateField = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    formState[field].errors = formService.validateField(e.currentTarget.value, formState[field].validation)
    formState[field].pristine = false
    setFormState({ ...formState })
    setFormIsValid(formService.validateForm(formState))
  }

  const handleFieldChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, field: MessageKeyTypes) => {
    message[field] = e.currentTarget.value
    dispatch(messageActions.setMessage({ ...message }))
    if (!formState[field].pristine) {
      validateField(e, field)
    }
  }

  const handleSubFieldChange = (
    e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: MessageContentKeyTypes
  ) => {
    message.message[field] = e.currentTarget.value
    dispatch(messageActions.setMessage({ ...message }))
    if (!formState[field].pristine) {
      validateField(e, field)
    }
  }

  const renderFieldErrors = (errors: string[]) => {
    return errors.map((error, index) => (
      <p className="error-message" key={index}>
        {error}
      </p>
    ))
  }

  const renderFormMessage = () => {
    if (formMessage?.type === 'none') {
      return null
    }
    return <p className={`form-message ${formMessage.type}`}>{formMessage.text}</p>
  }

  return (
    <form className="contact-form" onSubmit={handleSubmit} noValidate>
      {sent}
      <div className="form-row">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          maxLength={35}
          value={message.name}
          className={formState.name.errors.length ? 'error' : ''}
          onChange={(e) => handleFieldChange(e, 'name')}
          onBlur={(e) => validateField(e, 'name')}
        />
        {renderFieldErrors(formState.name.errors)}
      </div>
      <div className="form-row">
        <label htmlFor="email">E-mail</label>
        <input
          type="email"
          id="email"
          name="email"
          maxLength={40}
          value={message.email}
          className={formState.email.errors.length ? 'error' : ''}
          onChange={(e) => handleFieldChange(e, 'email')}
          onBlur={(e) => validateField(e, 'email')}
        />
        {renderFieldErrors(formState.email.errors)}
      </div>
      <div className="form-row">
        <label htmlFor="subject">Subject</label>
        <input
          type="text"
          id="subject"
          name="subject"
          maxLength={35}
          value={message.message.subject}
          className={formState.subject.errors.length ? 'error' : ''}
          onChange={(e) => handleSubFieldChange(e, 'subject')}
          onBlur={(e) => validateField(e, 'subject')}
        />
        {renderFieldErrors(formState.subject.errors)}
      </div>
      <div className="form-row">
        <label htmlFor="text">Message</label>
        <textarea
          rows={8}
          value={message.message.text}
          id="text"
          name="text"
          maxLength={250}
          className={formState.text.errors.length ? 'error' : ''}
          onChange={(e) => {
            handleSubFieldChange(e, 'text')
            validateField(e, 'text')
          }}
        ></textarea>
        {renderFieldErrors(formState.text.errors)}
      </div>
      <div className="form-row">
        <button type="submit" disabled={!formIsValid}>
          Send
        </button>
        {renderFormMessage()}
      </div>
      <div className="form-row">
        <p className="google-recaptcha-terms">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy" target="_blank">
            Privacy Policy
          </a>
          and
          <a href="https://policies.google.com/terms" target="_blank">
            Terms of Service
          </a>
          apply.
        </p>
      </div>
      <Loader show={loading}>Sending...</Loader>
    </form>
  )
}
